@font-face {
  font-family: Futura New;
  src: url("FuturaNewLight-Reg.c3e4e517.eot");
  src: local(Futura New Light Reg), local(FuturaNewLight-Reg), url("FuturaNewLight-Reg.c3e4e517.eot#iefix") format("embedded-opentype"), url("FuturaNewLight-Reg.9d1d45c1.woff2") format("woff2"), url("FuturaNewLight-Reg.edbe4070.woff") format("woff"), url("FuturaNewLight-Reg.9526e345.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBold-Reg.804ccc84.eot");
  src: local(Futura New Bold Reg), local(FuturaNewBold-Reg), url("FuturaNewBold-Reg.804ccc84.eot#iefix") format("embedded-opentype"), url("FuturaNewBold-Reg.7b608f5d.woff2") format("woff2"), url("FuturaNewBold-Reg.cd994ed8.woff") format("woff"), url("FuturaNewBold-Reg.ce513915.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Futura New;
  src: url("FuturaNewBook-Reg.08ed4795.eot");
  src: local(Futura New Book Reg), local(FuturaNewBook-Reg), url("FuturaNewBook-Reg.08ed4795.eot#iefix") format("embedded-opentype"), url("FuturaNewBook-Reg.24b9e46b.woff2") format("woff2"), url("FuturaNewBook-Reg.b7171b3b.woff") format("woff"), url("FuturaNewBook-Reg.f5a339aa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0;
  font-family: Futura New, Arial, sans-serif;
}

button:focus, input:focus {
  outline: none;
}

p, h1, h2, h3, ol, ul {
  margin: 0;
}

.overflow-hidden, .body-noscroll {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.owl-carousel:not(.owl-loaded) {
  opacity: 0;
  visibility: hidden;
  display: block;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  margin-top: 111px;
  padding: 0 16px;
}

@media (width >= 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (width >= 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (width >= 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.main__logo-text {
  width: 134px;
  height: 25px;
  margin-bottom: 24px;
}

.main__logo-text img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.main__button {
  color: #19647e66;
  background: #1f271b1a;
  border: 0;
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  font-weight: 600;
  line-height: 41px;
  box-shadow: -1px 3px 10px #0000000f;
}

.page {
  background: #f5f9fe;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  display: flex;
  overflow-x: hidden;
}

.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
  margin-bottom: 36px;
}

.page__footer {
  flex: none;
}

.input {
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.input__input {
  resize: none;
  color: #000;
  appearance: none;
  box-sizing: border-box;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  border-radius: 8px;
  outline: none;
  height: 36px;
  padding: 0 8px;
  font-size: 16px;
  line-height: 36px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: .2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: red;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: red;
}

.input_error .input__label {
  display: flex;
}

.input_error .input_error-text {
  color: #eb5757;
  letter-spacing: .05em;
  text-transform: none;
  white-space: nowrap;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: block;
  position: absolute;
  bottom: -16px;
}

.choice__info {
  z-index: 2000;
  position: fixed;
  inset: auto 8px 8px;
}

.choice__items {
  background: #f5f9fe;
  border-radius: 16px;
  max-height: 71vh;
  padding: 8px 12px;
  overflow: auto;
}

.choice__item {
  color: #000;
  background: #f5f9fe;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

.choice__item:first-child {
  border-radius: 16px 16px 0 0;
}

.choice__item:last-child {
  border-radius: 0 0 16px 16px;
  margin-bottom: 0;
}

.choice__item + .choice__item {
  padding-top: 4px;
  position: relative;
}

.choice__item + .choice__item:before {
  content: "";
  background: radial-gradient(98.81% 11088500% at 0 99.8%, #28afb066 0%, #28afb000 100%);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.choice__item svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.choice__button {
  text-align: center;
  color: #28afb0;
  background: #f5f9fe;
  border: 0;
  border-radius: 16px;
  width: 100%;
  height: 57px;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 57px;
}

.market {
  margin-top: 19px;
}

.market__title {
  color: #1f271b;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: normal;
  line-height: 22px;
  display: flex;
  position: relative;
}

.market__title svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
  margin-top: 4px;
  margin-left: 9px;
}

.market__logo {
  margin-top: 5px;
  position: absolute;
  right: 22px;
}

.headerContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.headerContainer span {
  text-align: center;
  max-width: 55%;
}

.headerContainer svg {
  margin-top: 9px;
  display: flex;
}

.market__logo svg {
  stroke: #1f271b;
}

.market__date {
  color: #60d6a9;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
}

.market__items {
  max-width: 359px;
  margin: 0 auto;
}

.market__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  justify-content: space-between;
  padding: 8px;
  display: flex;
}

.market__item + .market__item {
  margin-top: 8px;
}

.market__col_right {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.corner__list {
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4px;
  display: flex;
}

.order__status-time {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.order__status-small {
  padding-right: 50px;
  font-size: 12px;
  line-height: 1;
}

.order__status-waiting {
  color: #ffc700;
}

.order__status-confirmed {
  color: #60d6a9;
}

.order__status-refused {
  color: #c7c7d4;
}

.order__status-cooking {
  color: #ffa640;
}

.order__status-ready {
  color: #81fec6;
}

.order__status-waiting_on_counter, .order__status-issued_courier {
  color: #717179;
}

.order__status-received {
  color: #00bc29;
}

.order__time {
  color: #000;
  font-size: 12px;
  font-weight: 200;
  line-height: 1;
}

.market__row {
  align-items: center;
  display: flex;
}

.market__row + .market__row {
  margin-top: 4px;
}

.market__number {
  color: #000;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.market__tel {
  color: #19647e;
  opacity: .5;
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 14px;
}

.market__tel a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.market__sum {
  color: #28afb0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.market__name {
  color: #19647e;
  margin-right: 8px;
  font-size: 14px;
  line-height: 14px;
}

.order__eat_place {
  color: #19647e;
  opacity: .7;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
}

.market__status {
  color: #fff;
  white-space: nowrap;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 1;
}

.market__status_waiting {
  background: #ffc700;
}

.market__status_confirmed {
  background: #60d6a9;
}

.market__status_refused {
  background: #c7c7d4;
}

.market__status_expects {
  background: #ffc700;
}

.market__status_cooking {
  background: #ffa640;
}

.market__status_ready {
  color: #19647e;
  background: #81fec6;
}

.market__status_received {
  background: #00bc29;
}

.market__status_issued_courier {
  background: #555a57;
}

.order__status {
  white-space: nowrap;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 16px;
  line-height: 1;
}

.order__status_waiting {
  color: #19647e;
  background: #ffc700;
}

.order__status_confirmed {
  color: #fff;
  background: #60d6a9;
}

.order__status_refused {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_cooking {
  color: #19647e;
  background: #ffa640;
}

.order__status_ready {
  color: #19647e;
  background: #81fec6;
}

.order__status_readybtn {
  color: #fff;
  background: #00bc29;
}

.order__status_issued {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_received {
  color: #00bc29;
  background: # #fff;
}

.order__status_cancelled {
  color: #19647e;
  background: #c7c7d4;
}

.order__title {
  color: #1f271b;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
}

.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-left: 8px;
}

.order__logo svg {
  stroke: #1f271b;
  fill: none;
}

.order__table {
  max-width: 343px;
  margin: auto;
}

.order__total {
  color: #000;
  border-top: 1px dashed #0003;
  justify-content: space-between;
  max-width: 343px;
  margin: 8px auto 25px;
  padding-top: 10px;
  font-weight: 600;
  display: flex;
}

.order__total-text {
  text-transform: uppercase;
  font-size: 14px;
}

.order__total-sum {
  font-size: 16px;
}

.order__item + .order__item {
  margin-top: 8px;
}

.order__item_active .order__body {
  display: block;
}

.order__header {
  align-items: center;
  display: flex;
}

.order__header-img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.order__header-img img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.order__header-name {
  color: #19647e;
  font-size: 16px;
  line-height: 20px;
}

.order__header-btn {
  text-align: center;
  border-radius: 9px;
  height: 18px;
  margin-left: auto;
  padding: 0 32px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: -1px 3px 10px #0000000f;
}

.order__body {
  margin-top: 8px;
  display: none;
}

.order__body-row {
  display: flex;
}

.order__num {
  color: #000;
  width: 10px;
  min-width: 10px;
  margin-bottom: 2px;
  margin-right: 12px;
  font-size: 16px;
}

.order__dish {
  color: #000;
  width: 170px;
  margin-bottom: 2px;
  margin-right: 29px;
  font-size: 16px;
  font-weight: 500;
}

.order__dish-extra {
  width: 170px;
  margin-bottom: 8px;
  margin-right: 29px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__number {
  text-align: right;
  margin-bottom: 2px;
  font-size: 14px;
}

.order__number-extra {
  text-align: right;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__number_bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.order__sum {
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: auto;
  font-size: 14px;
}

.order__sum-extra {
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
}

.order__sum_md {
  font-size: 16px;
  line-height: 18px;
}

.order__itemnote {
  color: #19647e;
  padding-left: 25px;
  font-size: 16px;
  font-style: italic;
  line-height: 18px;
}

.order__body-item + .order__body-item, .order__footer {
  border-top: 1px dashed #0003;
  margin-top: 8px;
  padding-top: 8px;
}

.order__button_primary {
  color: #fff;
  background: #28afb0;
}

.popup-page__content {
  z-index: 2000;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-page__body {
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  width: 220px;
  padding: 24px 43px;
}

.popup-page__name {
  white-space: nowrap;
  color: #000;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 34px;
}

.popup-page__logout {
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.popup-page__logout svg {
  stroke: #fff;
  fill: none;
  stroke-width: 2px;
  margin-right: 8px;
}
/*# sourceMappingURL=index.25ae2b5e.css.map */
